import React from 'react';
import './App.css';
import { cities } from './cities.js';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.handleAutoCompleteSelection = this.handleAutoCompleteSelection.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.state = {
      autoComplete1Focus: -1,
      autoComplete2Focus: -1,
      autoComplete3Focus: -1,
      autoComplete4Focus: -1,
      autoComplete5Focus: -1,
      autoComplete6Focus: -1,
      dateInputMin: "",
      departureDate: "",
      focus: "",
      hideAutoComplete1: false,
      hideAutoComplete2: false,
      hideAutoComplete3: false,
      hideAutoComplete4: false,
      hideAutoComplete5: false,
      hideAutoComplete6: false,
      loadingCity: 1627,
      origin1: "",
      origin1Matches: [],
      origin2: "",
      origin2Matches: [],
      origin3: "",
      origin3Matches: [],
      origin4: "",
      origin4Matches: [],
      origin5: "",
      origin5Matches: [],
      origin6: "",
      origin6Matches: [],
      originCount: 2,
      returnDate: "",
      stateMessage: "invalid",
      // invalid, loading, empty, error, or null
      summedCheapestFlights: [],
      tripLength: 2
    };
  }

  // Calculate date of next specified day of the week (e.g. next Friday)
  nextWeekdayDate(date, day) {
    var nextDaysDate = new Date(date || new Date());
    nextDaysDate.setDate(nextDaysDate.getDate() + (day - 1 - nextDaysDate.getDay() + 7) % 7 + 1);
    return nextDaysDate;
  }

  // Format date so date input can be set to output value (default JS date format to YYYY-MM-DD date format)
  formatInputDate(rawDate) {
    var formattedDate = rawDate.getFullYear() + '-' + ((rawDate.getMonth() > 8) ? (rawDate.getMonth() + 1) : ('0' + (rawDate.getMonth() + 1))) + '-' + ((rawDate.getDate() > 9) ? rawDate.getDate() : ('0' + rawDate.getDate()));
    return formattedDate;
  }

  // Format date so it can be used for date math (YYYY-MM-DD date format to default JS date format)
  unformatInputDate(formattedDate) {
    var ymd = formattedDate.split('-');
    var rawDate = new Date(ymd[0], ymd[1] - 1, ymd[2]);
    return rawDate;
  }

  // Find date a specified number of days later than input date (takes default JS date format)
  addDays(originalDate, days) {
    return new Date(originalDate.setDate(originalDate.getDate() + days));
  }

  // Find the number of days from date1 to date2 (takes YYYY-MM-DD date format)
  dateDifference(date1, date2) {
    var rawDate1 = this.unformatInputDate(date1);
    var rawDate2 = this.unformatInputDate(date2);
    return Math.round((rawDate2 - rawDate1) / (1000 * 60 * 60 * 24));
  }

  // Handle all input changes
  handleChange(event) {
    // For departure date change,
    if (event.target.name === "departureDate") {
      var previousTripLength = this.dateDifference(this.state.departureDate, this.state.returnDate);
      var targetTripLength = this.dateDifference(event.target.value, this.state.returnDate);
      // If new departure date is after old return date,
      if (targetTripLength < 0) {
        // Set return date to new departure date plus previous trip length
        var rawTargetDepartureDate = this.unformatInputDate(event.target.value);
        var adjustedReturnDate = this.addDays(rawTargetDepartureDate, previousTripLength);
        var formattedAdjustedReturnDate = this.formatInputDate(adjustedReturnDate);
        this.setState({
          returnDate: formattedAdjustedReturnDate
        });
      }
    }
    // For return date change,
    if (event.target.name === "returnDate") {
      previousTripLength = this.dateDifference(this.state.departureDate, this.state.returnDate);
      targetTripLength = this.dateDifference(this.state.departureDate, event.target.value);
      // If new return date is before old departure date,
      if (targetTripLength < 0) {
        // Set departure date to new return date minus previous trip length
        var rawTargetReturnDate = this.unformatInputDate(event.target.value);
        var adjustedDepartureDate = this.addDays(rawTargetReturnDate, -previousTripLength);
        var formattedAdjustedDepartureDate = this.formatInputDate(adjustedDepartureDate);
        this.setState({
          departureDate: formattedAdjustedDepartureDate
        });
      }
    }
    // Set state for input to target value
    this.setState({
      [event.target.name]: event.target.value
    }, () => {
      // FIXME: Clean up and improve performance; don't set states if no text has been entered (don't display matches when all cities match)
      var origin1 = this.state.origin1;
      var origin2 = this.state.origin2;
      var origin3 = this.state.origin3;
      var origin4 = this.state.origin4;
      var origin5 = this.state.origin5;
      var origin6 = this.state.origin6;
      function checkMatch1(city) {
        return origin1.toUpperCase() === (city[0]).substr(0, origin1.length).toUpperCase();
      }
      function checkMatch2(city) {
        return origin2.toUpperCase() === (city[0]).substr(0, origin2.length).toUpperCase();
      }
      function checkMatch3(city) {
        return origin3.toUpperCase() === (city[0]).substr(0, origin3.length).toUpperCase();
      }
      function checkMatch4(city) {
        return origin4.toUpperCase() === (city[0]).substr(0, origin4.length).toUpperCase();
      }
      function checkMatch5(city) {
        return origin5.toUpperCase() === (city[0]).substr(0, origin5.length).toUpperCase();
      }
      function checkMatch6(city) {
        return origin6.toUpperCase() === (city[0]).substr(0, origin6.length).toUpperCase();
      }
      var matchingCities1 = cities.filter(checkMatch1);
      var matchingCities2 = cities.filter(checkMatch2);
      var matchingCities3 = cities.filter(checkMatch3);
      var matchingCities4 = cities.filter(checkMatch4);
      var matchingCities5 = cities.filter(checkMatch5);
      var matchingCities6 = cities.filter(checkMatch6);
      this.setState({
        origin1Matches: matchingCities1,
        origin2Matches: matchingCities2,
        origin3Matches: matchingCities3,
        origin4Matches: matchingCities4,
        origin5Matches: matchingCities5,
        origin6Matches: matchingCities6
      });
    });
  }

  // Handle unfocusing any input
  handleBlur() {
    // Clear autocomplete active items and focus
    this.setState({
      autoComplete1Focus: -1,
      autoComplete2Focus: -1,
      autoComplete3Focus: -1,
      autoComplete4Focus: -1,
      autoComplete5Focus: -1,
      autoComplete6Focus: -1,
      focus: ""
    });
  }

  // Handle focusing any input
  handleFocus(event) {
    this.setState({
      focus: event.target.name
    });
  }

  componentDidMount() {
    // Set timer for and animate loading city
    this.interval = setInterval(() => this.setState({
      loadingCity: Math.floor(Math.random() * Math.floor(cities.length))
    }), 100);
    var date = new Date();
    var formattedDate = this.formatInputDate(date);
    // Find and format date of departure Friday
    var thisFriday = this.nextWeekdayDate(date, 5);
    var departureFriday = this.addDays(thisFriday, 21);
    var formattedDepartureFriday = this.formatInputDate(departureFriday)
    // Find and format date of return Sunday
    var returnSunday = this.addDays(departureFriday, 2);
    var formattedReturnSunday = this.formatInputDate(returnSunday)
    this.setState({
      dateInputMin: formattedDate,
      departureDate: formattedDepartureFriday,
      returnDate: formattedReturnSunday
    });
    // Update focus on autocompletes when up or down arrow is pressed
    window.addEventListener("keydown", (event) => {
      var autoComplete1Focus = this.state.autoComplete1Focus;
      var autoComplete2Focus = this.state.autoComplete2Focus;
      var autoComplete3Focus = this.state.autoComplete3Focus;
      var autoComplete4Focus = this.state.autoComplete4Focus;
      var autoComplete5Focus = this.state.autoComplete5Focus;
      var autoComplete6Focus = this.state.autoComplete6Focus;
      var focus = this.state.focus;
      var keyCode = event.keyCode;
      var origin1 = this.state.origin1;
      var origin2 = this.state.origin2;
      var origin3 = this.state.origin3;
      var origin4 = this.state.origin4;
      var origin5 = this.state.origin5;
      var origin6 = this.state.origin6;
      var origin1Matches = this.state.origin1Matches;
      var origin2Matches = this.state.origin2Matches;
      var origin3Matches = this.state.origin3Matches;
      var origin4Matches = this.state.origin4Matches;
      var origin5Matches = this.state.origin5Matches;
      var origin6Matches = this.state.origin6Matches;
      // If up arrow is pressed
      if (keyCode === 38) {
        // ... and origin1 is in focus, and there is an active city, move active city up one in menu
        if (focus === "origin1" && autoComplete1Focus > -1) {
          this.setState({
            autoComplete1Focus: (autoComplete1Focus - 1)
          });
        }
        // ... and origin2 is in focus, and there is an active city, move active city up one in menu
        if (focus === "origin2" && autoComplete2Focus > -1) {
          this.setState({
            autoComplete2Focus: (autoComplete2Focus - 1)
          });
        }
        // ... and origin3 is in focus, and there is an active city, move active city up one in menu
        if (focus === "origin3" && autoComplete3Focus > -1) {
          this.setState({
            autoComplete3Focus: (autoComplete3Focus - 1)
          });
        }
        // ... and origin4 is in focus, and there is an active city, move active city up one in menu
        if (focus === "origin4" && autoComplete4Focus > -1) {
          this.setState({
            autoComplete4Focus: (autoComplete4Focus - 1)
          });
        }
        // ... and origin5 is in focus, and there is an active city, move active city up one in menu
        if (focus === "origin5" && autoComplete5Focus > -1) {
          this.setState({
            autoComplete5Focus: (autoComplete5Focus - 1)
          });
        }
        // ... and origin6 is in focus, and there is an active city, move active city up one in menu
        if (focus === "origin6" && autoComplete6Focus > -1) {
          this.setState({
            autoComplete6Focus: (autoComplete6Focus - 1)
          });
        }
      }
      // If down arrow is pressed
      if (keyCode === 40) {
        // ... and origin1 is in focus, move active city down one in menu
        if (focus === "origin1") {
          this.setState({
            autoComplete1Focus: (autoComplete1Focus + 1)
          });
        }
        // ... and origin2 is in focus, move active city down one in menu
        if (focus === "origin2") {
          this.setState({
            autoComplete2Focus: (autoComplete2Focus + 1)
          });
        }
        // ... and origin3 is in focus, move active city down one in menu
        if (focus === "origin3") {
          this.setState({
            autoComplete3Focus: (autoComplete3Focus + 1)
          });
        }
        // ... and origin4 is in focus, move active city down one in menu
        if (focus === "origin4") {
          this.setState({
            autoComplete4Focus: (autoComplete4Focus + 1)
          });
        }
        // ... and origin5 is in focus, move active city down one in menu
        if (focus === "origin5") {
          this.setState({
            autoComplete5Focus: (autoComplete5Focus + 1)
          });
        }
        // ... and origin6 is in focus, move active city down one in menu
        if (focus === "origin6") {
          this.setState({
            autoComplete6Focus: (autoComplete6Focus + 1)
          });
        }
      }
      // If any key other than enter or return is pressed
      if (keyCode !== 13) {
        // Stop manually hiding all autocompletes
        this.setState({
          hideAutoComplete1: false,
          hideAutoComplete2: false,
          hideAutoComplete3: false,
          hideAutoComplete4: false,
          hideAutoComplete5: false,
          hideAutoComplete6: false
        });
      }
      // If enter or return is pressed
      if (keyCode === 13) {
        // Get results if no autocomplete city is active, no origin input is empty, and an input is in focus
        // FIXME: don't require values for hidden inputs
        if (
          autoComplete1Focus === -1 &&
          autoComplete2Focus === -1 &&
          origin1 !== "" &&
          origin2 !== "" &&
          origin3 !== "" &&
          origin4 !== "" &&
          origin5 !== "" &&
          origin6 !== "" &&
          (
            focus === "origin1" ||
            focus === "origin2" ||
            focus === "origin3" ||
            focus === "origin4" ||
            focus === "origin5" ||
            focus === "origin6" ||
            focus === "departureDate" ||
            focus === "returnDate"
          )
        ) {
          this.getResults();
        }
        // When active city is selected on enter or return, reset focus, hide autocomplete, and set input to IATA code
        if (autoComplete1Focus > -1) {
          this.setState({
            autoComplete1Focus: -1,
            hideAutoComplete1: true,
            origin1: origin1Matches[autoComplete1Focus][2]
          })
        }
        if (autoComplete2Focus > -1) {
          this.setState({
            autoComplete2Focus: -1,
            hideAutoComplete2: true,
            origin2: origin2Matches[autoComplete2Focus][2]
          })
        }
        if (autoComplete3Focus > -1) {
          this.setState({
            autoComplete3Focus: -1,
            hideAutoComplete3: true,
            origin3: origin3Matches[autoComplete3Focus][2]
          })
        }
        if (autoComplete4Focus > -1) {
          this.setState({
            autoComplete4Focus: -1,
            hideAutoComplete4: true,
            origin4: origin4Matches[autoComplete4Focus][2]
          })
        }
        if (autoComplete5Focus > -1) {
          this.setState({
            autoComplete5Focus: -1,
            hideAutoComplete5: true,
            origin5: origin5Matches[autoComplete5Focus][2]
          })
        }
        if (autoComplete6Focus > -1) {
          this.setState({
            autoComplete6Focus: -1,
            hideAutoComplete6: true,
            origin6: origin6Matches[autoComplete6Focus][2]
          })
        }
      }
    });
  }

  componentWillUnmount() {
    clearInterval(this.inverval);
  }

  addOrigin = () => {
    var originCount = this.state.originCount;
    var targetOriginCount = originCount + 1;
    this.setState({
      originCount: targetOriginCount
    });
  }

  formatUrlDate(inputDate) {
    let urlSpace = "%2F";
    let year = inputDate.slice(0, 4);
    let month = inputDate.slice(5, 7);
    let day = inputDate.slice(8, 10);
    return day + urlSpace + month + urlSpace + year;
  }

  getResults = () => {
    // Close autocompletes and show loading state
    this.setState({
      hideAutoComplete1: true,
      hideAutoComplete2: true,
      hideAutoComplete3: true,
      hideAutoComplete4: true,
      hideAutoComplete5: true,
      hideAutoComplete6: true,
      stateMessage: "loading"
    });
    // Format departure and return dates for creating fetch URLs
    var departureDateFormatted = this.formatUrlDate(this.state.departureDate);
    var returnDateFormatted = this.formatUrlDate(this.state.returnDate);
    // Create fetch URLs from variables
    var kiwiUrl1 = "https://tequila-api.kiwi.com/v2/search?fly_from=" + this.state.origin1 + "&date_from=" + departureDateFormatted + "&date_to=" + departureDateFormatted + "&return_from=" + returnDateFormatted + "&return_to=" + returnDateFormatted + "&max_fly_duration=30&flight_type=round&adults=1&partner_market=us&curr=USD&locale=us&max_stopovers=3&vehicle_type=aircraft&ret_from_diff_airport=0&ret_to_diff_airport=0"
    var kiwiUrl2 = "https://tequila-api.kiwi.com/v2/search?fly_from=" + this.state.origin2 + "&date_from=" + departureDateFormatted + "&date_to=" + departureDateFormatted + "&return_from=" + returnDateFormatted + "&return_to=" + returnDateFormatted + "&max_fly_duration=30&flight_type=round&adults=1&partner_market=us&curr=USD&locale=us&max_stopovers=3&vehicle_type=aircraft&ret_from_diff_airport=0&ret_to_diff_airport=0"
    // Fetch Origin 1 flights
    fetch(kiwiUrl1, {
      headers: {
        Accept: "application/json",
        Apikey: "qlogezir74juAC8pfpCYxZJqTTGlncv6"
      }
    })
      .then((response) => response.json())
      .then((responseJSON) => {
        // Group Origin 1 flights by destination city
        var flights = responseJSON.data;
        function groupBy(array, property) {
          var hash = {};
          for (var i = 0; i < array.length; i++) {
            if (!hash[array[i][property]]) hash[array[i][property]] = [];
            hash[array[i][property]].push(array[i]);
          }
          return hash;
        }
        var groupedFlights1 = groupBy(flights, 'cityTo')
        return groupedFlights1;
      })
      .then((groupedFlights1) => {
        // Fetch Origin 2 flights
        fetch(kiwiUrl2, {
          headers: {
            Accept: "application/json",
            Apikey: "qlogezir74juAC8pfpCYxZJqTTGlncv6"
          }
        })
          .then((response) => response.json())
          .then((responseJSON) => {
            // Group Origin 12 flights by destination city
            var flights = responseJSON.data;
            function groupBy(array, property) {
              var hash = {};
              if (typeof array !== 'undefined') {
                for (var i = 0; i < array.length; i++) {
                  if (!hash[array[i][property]]) hash[array[i][property]] = [];
                  hash[array[i][property]].push(array[i]);
                }
              }
              return hash;
            }
            var groupedFlights2 = groupBy(flights, 'cityTo');
            // Remove grouped arrays of flights from wrapping objects
            var groupedFlights1Arr = Object.entries(groupedFlights1);
            var groupedFlights2Arr = Object.entries(groupedFlights2);
            // Create an array containing the cheapest flight to each city
            var cheapestFlights1 = groupedFlights1Arr.map(function (x) {
              return (x[1])[0];
            });
            var cheapestFlights2 = groupedFlights2Arr.map(function (x) {
              return (x[1])[0];
            });
            // Concatenate arrays of cheapest flights and regroup by destination
            var allCheapestFlights = cheapestFlights1.concat(cheapestFlights2);
            var groupedCheapestFlights = groupBy(allCheapestFlights, 'cityTo');
            var groupedCheapestFlightsArr = Object.entries(groupedCheapestFlights);
            // Create separate array for each group of cheapest flights to single destination 
            let pairedCheapestFlights = [];
            for (let i = 0; i < groupedCheapestFlightsArr.length; i++) {
              if ((groupedCheapestFlightsArr[i])[1].length > 1) {
                pairedCheapestFlights.push(groupedCheapestFlightsArr[i]);
              }
            }
            // Find total ticket price of each group of cheapest flights to single destination
            var summedCheapestFlights = pairedCheapestFlights.map(function (x) {
              var sumPrice = (x[1])[0].price + (x[1])[1].price;
              return [sumPrice, x[0], x[1]]
            })
            if (summedCheapestFlights.length === 0) {
              this.setState({
                stateMessage: "empty",
                summedCheapestFlights: summedCheapestFlights
              });
            }
            // Sort grouped flights from lowest to highest total ticket price
            summedCheapestFlights.sort((a, b) => {
              if (a[0] > b[0])
                return 1;
              if (a[0] < b[0])
                return -1;
              return 0;
            });
            if (summedCheapestFlights.length > 0) {
              this.setState({
                stateMessage: "",
                summedCheapestFlights: summedCheapestFlights
              });
            }
          })
      })
      .catch(() => {
        this.setState({
          stateMessage: "error",
        });
      });
  }

  Results(props) {
    const flights = props.flights;
    // For each result city, create a result item
    const results = flights.map((flight) =>
      <div className="result-item" key={flight[1] + ", " + flight[0]}>
        <div className="result-headline">
          <h2 className="suggested-city">{flight[1] + "!"}</h2>
          <h3>{"$" + flight[0] + " total price"}</h3>
        </div>
        {/* For each result flight, create a flight itme */}
        <div className="result-details">
          <div className="flight">
            <h4 className="flight-cities">{flight[2][0].cityFrom + " ⇄ " + flight[2][0].cityTo}</h4>
            {/* Show details about the first flight */}
            <div className="grid-wrapper">
              <span className="flight-detail-span">{flight[2][0].cityCodeFrom + "→" + flight[2][0].cityCodeTo}</span>
              <span className="flight-detail-span">{Math.floor(flight[2][0].duration.departure / 3600) + "h " + (flight[2][0].duration.departure % 3600) / 60 + "m"}</span>
              <span className="flight-detail-span">{(flight[2][0].route.filter((obj) => obj.return === 0).length === 1) ? "Nonstop" : (flight[2][0].route.filter((obj) => obj.return === 0).length - 1) + ((flight[2][0].route.filter((obj) => obj.return === 0).length === 2) ? " stop" : " stops")}</span>
              <span className="flight-detail-span">{flight[2][0].cityCodeTo + "→" + flight[2][0].cityCodeFrom}</span>
              <span className="flight-detail-span">{Math.floor(flight[2][0].duration.return / 3600) + "h " + (flight[2][0].duration.return % 3600) / 60 + "m"}</span>
              <span className="flight-detail-span">{(flight[2][0].route.filter((obj) => obj.return === 1).length === 1) ? "Nonstop" : (flight[2][0].route.filter((obj) => obj.return === 1).length - 1) + ((flight[2][0].route.filter((obj) => obj.return === 1).length === 2) ? " stop" : " stops")}</span>
            </div>
            <a className="button-link" href={flight[2][0].deep_link} rel="noopener noreferrer" target="_blank">{"Book for $" + flight[2][0].price}</a>
            {/* Copy book link to clipboard */}
            <button
              className="link-button"
              onClick={(event) => {
                const target = event.target;
                target.innerHTML = "Copied!";
                setTimeout(() => {
                  target.innerHTML = "Copy link"
                }, 3000);
                navigator.clipboard.writeText(flight[2][0].deep_link)
              }}
            >
              Copy link
            </button>
          </div>
          <div className="flight">
            <h4 className="flight-cities">{flight[2][1].cityFrom + " ⇄ " + flight[2][1].cityTo}</h4>
            {/* Show details about the second flight */}
            <div className="grid-wrapper">
              <span className="flight-detail-span">{flight[2][1].cityCodeFrom + "→" + flight[2][1].cityCodeTo}</span>
              <span className="flight-detail-span">{Math.floor(flight[2][1].duration.departure / 3600) + "h " + (flight[2][1].duration.departure % 3600) / 60 + "m"}</span>
              <span className="flight-detail-span">{(flight[2][1].route.filter((obj) => obj.return === 0).length === 1) ? "Nonstop" : (flight[2][1].route.filter((obj) => obj.return === 0).length - 1) + ((flight[2][1].route.filter((obj) => obj.return === 0).length === 2) ? " stop" : " stops")}</span>
              <span className="flight-detail-span">{flight[2][1].cityCodeTo + "→" + flight[2][1].cityCodeFrom}</span>
              <span className="flight-detail-span">{Math.floor(flight[2][1].duration.return / 3600) + "h " + (flight[2][1].duration.return % 3600) / 60 + "m"}</span>
              <span className="flight-detail-span">{(flight[2][1].route.filter((obj) => obj.return === 1).length === 1) ? "Nonstop" : (flight[2][1].route.filter((obj) => obj.return === 1).length - 1) + ((flight[2][1].route.filter((obj) => obj.return === 1).length === 2) ? " stop" : " stops")}</span>
            </div>
            <a className="button-link" href={flight[2][1].deep_link} rel="noopener noreferrer" target="_blank">{"Book for $" + flight[2][1].price}</a>
            {/* Copy book link to clipboard */}
            <button
              className="link-button"
              onClick={(event) => {
                const target = event.target;
                target.innerHTML = "Copied!";
                setTimeout(() => {
                  target.innerHTML = "Copy link"
                }, 3000);
                navigator.clipboard.writeText(flight[2][1].deep_link)
              }}
            >
              Copy link
            </button>
          </div>
        </div>
      </div>
    );
    return (
      // Display the results
      <div id="results">{results}</div>
    );
  }

  handleAutoCompleteSelection(targetCityCode) {
    this.setState({
      [this.state.focus]: targetCityCode,
    });
  }

  AutoComplete(props) {
    var activeItem = props.activeItem;
    var currentValue = props.currentValue;
    var matchingCities = props.matchingCities;
    var updateValue = props.updateValue;
    var matches = matchingCities.map((city, index) =>
      <div
        className={index === activeItem ? "autocomplete-active" : null}
        key={city[0] + ", " + city[1] + ", " + city[2]}
        onMouseDown={() => updateValue(city[2])}
      >
        <div className="location-name"><strong>{(city[0] + ", " + city[1]).substr(0, currentValue.length)}</strong>{(city[0] + ", " + city[1]).substr(currentValue.length)}</div>
        <span className="iata-code">{city[2]}</span>
        <input type="hidden" value={city[2]} />
      </div>
    );
    return (
      <div className="autocomplete-items">{matches}</div>
    );
  }

  render() {
    return (
      <div className="app-wrapper">
        <div className="left-section">
          <h2>Fly cheap, see your people</h2>
          <div>
            <div className="input-wrapper">
              <div><label htmlFor="origin1">You're Coming From</label></div>
              <div className="autocomplete">
                <input
                  autoComplete="off"
                  name="origin1"
                  onBlur={this.handleBlur}
                  onChange={this.handleChange}
                  onFocus={this.handleFocus}
                  placeholder="Search cities…"
                  type="text"
                  value={this.state.origin1}
                />
                {this.state.focus === "origin1" &&
                  this.state.origin1 !== "" &&
                  this.state.hideAutoComplete1 !== true ?
                  <this.AutoComplete
                    activeItem={this.state.autoComplete1Focus}
                    currentValue={this.state.origin1}
                    matchingCities={this.state.origin1Matches}
                    updateValue={this.handleAutoCompleteSelection}
                  />
                  : null
                }
              </div>
            </div>
            <div className="input-wrapper">
              <div><label htmlFor="origin2">To Meet Someone From</label></div>
              <div className="autocomplete">
                <input
                  autoComplete="off"
                  name="origin2"
                  onBlur={this.handleBlur}
                  onChange={this.handleChange}
                  onFocus={this.handleFocus}
                  placeholder="Search cities…"
                  type="text"
                  value={this.state.origin2}
                />
                {this.state.focus === "origin2" &&
                  this.state.origin2 !== "" &&
                  this.state.hideAutoComplete2 !== true ?
                  <this.AutoComplete
                    activeItem={this.state.autoComplete2Focus}
                    currentValue={this.state.origin2}
                    matchingCities={this.state.origin2Matches}
                    updateValue={this.handleAutoCompleteSelection}
                  />
                  : null
                }
              </div>
            </div>
            {this.state.originCount >= 3 ?
              <div className="input-wrapper">
                <div><label htmlFor="origin3">To Meet Someone From</label></div>
                <div className="autocomplete">
                  <input
                    autoComplete="off"
                    name="origin3"
                    onBlur={this.handleBlur}
                    onChange={this.handleChange}
                    onFocus={this.handleFocus}
                    placeholder="Search cities…"
                    type="text"
                    value={this.state.origin3}
                  />
                  {this.state.focus === "origin3" &&
                    this.state.origin3 !== "" &&
                    this.state.hideAutoComplete3 !== true ?
                    <this.AutoComplete
                      activeItem={this.state.autoComplete3Focus}
                      currentValue={this.state.origin3}
                      matchingCities={this.state.origin3Matches}
                      updateValue={this.handleAutoCompleteSelection}
                    />
                    : null
                  }
                </div>
              </div>
              : null
            }
            {this.state.originCount >= 4 ?
              <div className="input-wrapper">
                <div><label htmlFor="origin4">To Meet Someone From</label></div>
                <div className="autocomplete">
                  <input
                    autoComplete="off"
                    name="origin4"
                    onBlur={this.handleBlur}
                    onChange={this.handleChange}
                    onFocus={this.handleFocus}
                    placeholder="Search cities…"
                    type="text"
                    value={this.state.origin4}
                  />
                  {this.state.focus === "origin4" &&
                    this.state.origin4 !== "" &&
                    this.state.hideAutoComplete4 !== true ?
                    <this.AutoComplete
                      activeItem={this.state.autoComplete4Focus}
                      currentValue={this.state.origin4}
                      matchingCities={this.state.origin4Matches}
                      updateValue={this.handleAutoCompleteSelection}
                    />
                    : null
                  }
                </div>
              </div>
              : null
            }
            {this.state.originCount >= 5 ?
              <div className="input-wrapper">
                <div><label htmlFor="origin5">To Meet Someone From</label></div>
                <div className="autocomplete">
                  <input
                    autoComplete="off"
                    name="origin5"
                    onBlur={this.handleBlur}
                    onChange={this.handleChange}
                    onFocus={this.handleFocus}
                    placeholder="Search cities…"
                    type="text"
                    value={this.state.origin5}
                  />
                  {this.state.focus === "origin5" &&
                    this.state.origin5 !== "" &&
                    this.state.hideAutoComplete5 !== true ?
                    <this.AutoComplete
                      activeItem={this.state.autoComplete5Focus}
                      currentValue={this.state.origin5}
                      matchingCities={this.state.origin5Matches}
                      updateValue={this.handleAutoCompleteSelection}
                    />
                    : null
                  }
                </div>
              </div>
              : null
            }
            {this.state.originCount >= 6 ?
              <div className="input-wrapper">
                <div><label htmlFor="origin6">To Meet Someone From</label></div>
                <div className="autocomplete">
                  <input
                    autoComplete="off"
                    name="origin6"
                    onBlur={this.handleBlur}
                    onChange={this.handleChange}
                    onFocus={this.handleFocus}
                    placeholder="Search cities…"
                    type="text"
                    value={this.state.origin6}
                  />
                  {this.state.focus === "origin6" &&
                    this.state.origin6 !== "" &&
                    this.state.hideAutoComplete6 !== true ?
                    <this.AutoComplete
                      activeItem={this.state.autoComplete6Focus}
                      currentValue={this.state.origin6}
                      matchingCities={this.state.origin6Matches}
                      updateValue={this.handleAutoCompleteSelection}
                    />
                    : null
                  }
                </div>
              </div>
              : null
            }
            {/* {this.state.originCount < 6 ?
              <button className="link-button" onClick={this.addOrigin}>Add origin</button>
              : null
            } */}
            <div className="input-wrapper date-input-wrapper">
              <div><label htmlFor="departureDate">Depart On</label></div>
              <div>
                <input
                  min={this.state.dateInputMin}
                  name="departureDate"
                  onBlur={this.handleBlur}
                  onChange={this.handleChange}
                  onFocus={this.handleFocus}
                  type="date"
                  value={this.state.departureDate}
                />
              </div>
            </div>
            <div className="input-wrapper date-input-wrapper">
              <div><label htmlFor="returnDate">Return On</label></div>
              <div>
                <input
                  min={this.state.dateInputMin}
                  name="returnDate"
                  onBlur={this.handleBlur}
                  onChange={this.handleChange}
                  onFocus={this.handleFocus}
                  type="date"
                  value={this.state.returnDate}
                />
              </div>
            </div>
          </div>
          <button
            disabled={this.state.origin1 === "" || this.state.origin2 === ""}
            id="search-button"
            onClick={this.getResults}
          >
            {this.state.stateMessage === "loading" ? "Searching…" : "Find Rendezvous!"}
          </button>
          {/* <div className="footer">
            <a href="/about">About</a><span className="copyright">© 2020 Sam Summer Design</span>
          </div> */}
        </div>
        <div className="right-section">
          {this.state.stateMessage === "invalid" && !this.state.summedCheapestFlights[0] ?
            <div className="state" id="invalid-state">
              <div className="state-inner-flex-wrapper">
                <svg height="5em" viewBox="0 0 56.6 100">
                  <path d="M56.6 28.3c0 15.6-28.3 48.4-28.3 48.4S0 43.9 0 28.3 12.7 0 28.3 0s28.3 12.7 28.3 28.3z" />
                  <circle cx="28.3" cy="90.3" r="9.7" />
                </svg>
                <h2>Add cities of origin and pick your departure and return dates to find the best place to meet up!</h2>
              </div>
            </div>
            : null}
          {this.state.stateMessage === "loading" ?
            <div className="state" id="loading-state">
              <div className="state-inner-flex-wrapper">
                <svg height="5em" viewBox="0 0 56.6 100">
                  <path d="M56.6 28.3c0 15.6-28.3 48.4-28.3 48.4S0 43.9 0 28.3 12.7 0 28.3 0s28.3 12.7 28.3 28.3z" />
                  <circle cx="28.3" cy="90.3" r="9.7" />
                </svg>
                <div>Checking 2,000 cities to find the cheapest flights:</div>
                <h2 id="loading-state-city">{cities[this.state.loadingCity][0]}</h2>
              </div>
            </div>
            : null}
          {this.state.stateMessage === "empty" ?
            <div className="state" id="empty-state">
              <div className="state-inner-flex-wrapper">
                <svg height="5em" viewBox="0 0 56.6 100">
                  <path d="M56.6 28.3c0 15.6-28.3 48.4-28.3 48.4S0 43.9 0 28.3 12.7 0 28.3 0s28.3 12.7 28.3 28.3z" />
                  <circle cx="28.3" cy="90.3" r="9.7" />
                </svg>
                <h2>Looks like there aren't any flights that match your search.</h2>
                <div>Try using different origins or dates.</div>
              </div>
            </div>
            : null}
          {this.state.stateMessage === "error" ?
            <div className="state" id="error-state">
              <div className="state-inner-flex-wrapper">
                <svg height="5em" viewBox="0 0 56.6 100">
                  <path d="M56.6 28.3c0 15.6-28.3 48.4-28.3 48.4S0 43.9 0 28.3 12.7 0 28.3 0s28.3 12.7 28.3 28.3z" />
                  <circle cx="28.3" cy="90.3" r="9.7" />
                </svg>
                <h2>Oops, looks like something went wrong.</h2>
                <div>Make sure you're using real airport codes and valid dates.</div>
              </div>
            </div>
            : null}
          {this.state.summedCheapestFlights[0] && !(this.state.stateMessage === "loading") ?
            <this.Results flights={this.state.summedCheapestFlights} />
            : null}
        </div>
        <div className="snackbar" id="copy-snackbar">Copied!</div>
      </div>
    );
  }
}

export default App;
